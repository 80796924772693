import LoadingOverlay from "./LoadingOverlay";

const Loader = () => {

    return(
        <div style={{maxWidth:'100%', maxHeight:'100%', minHeight:'650px'}}>
           <div className='loader'/>
        </div>
    )

}

export default Loader;