import React from "react";
import { myAxios } from "./Helper";

async function getServices(cityId) {
    return await myAxios.get('/api/service/city/'+cityId+'/status?status=true',{
        withCredentials: true,
    }).then((response) => response.data)
};

async function getServiceCategory(serviceName, cityName) {
    return await myAxios.get('/api/service-category/city/'+cityName+'/service/'+serviceName+'?status=true',{
        withCredentials: true,
    }).then((response) => response.data)
};

async function getServiceItems(serviceCategoryId, city_id) {
    return await myAxios.get('/api/service-item/city/'+city_id+'/service-category/'+serviceCategoryId+'?status=true',{
        withCredentials: true,
    }).then((response) => response.data)
};

async function searchItem(searchQuery, cityId) {
    return await myAxios.get('/api/service-item/city/'+cityId+'/search?status=true&searchQuery='+searchQuery,{
        withCredentials: true,
    }).then((response) => response.data)
};

///////
async function getFoodItemsByCategory(category) {
    return await myAxios.get('/restaurants-service/items/category/' + category).then((response) => response.data)
};

async function searchFoodItems(keyword) {
    return await myAxios.get('/restaurants-service/items/search/' + keyword).then((response) => response.data)
};


export { getServices,getServiceCategory,getServiceItems, getFoodItemsByCategory, searchItem };