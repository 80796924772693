import React from "react";
import {Navigation} from "./Navigation";

  const Header = () =>
    {
        return (
            <div>
                <Navigation />
            </div>

        );
    };

export default Header;
