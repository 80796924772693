import React from "react";
import { myAxios } from "./Helper";


async function getUserByUserId(userId) {
  return await myAxios.get('/api/user/customerId/' + userId, {
    withCredentials: true,
  }).then((response) => response.data)
}

async function updateUser(userId, user) {
  return await myAxios.put('/api/auth/user/' + userId, user, {
    withCredentials: true,
  }).then((response) => response.data)
}

async function saveAddress(addressData, userId) {
  return await myAxios.put('/api/user/userId/' + userId + '/add-address', {
    address: addressData
  }, {
    withCredentials: true,
  }).then((response) => response.data)
}

async function deleteAddress(userId, addressId) {
  return await myAxios.delete('/api/user/userId/'+userId+'/address/'+addressId, {
    withCredentials: true,
  }).then((response) => response.data)
}

////////////////////////////
async function getUserByUserName(userName) {
  return await myAxios.get('/auth-service/user/userName/' + userName,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
    .then((response) => response);
}

async function getUserAddress(userId, addressId) {
  return await myAxios.get('/auth-service/user/id/' + userId + '/addess/' + addressId,
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }
  )
    .then((response) => response);
}



export { getUserByUserId, getUserByUserName, getUserAddress, saveAddress, deleteAddress, updateUser };