import { myAxios } from "./Helper";

async function getLocation() {

    return await myAxios.get('/api/centre/all')
        .then((response) => response);
 };

 async function setCity(city) {
    return await myAxios.get('/api/centre?cityName='+city,{
        withCredentials: true,
    })
        .then((response) => response);
 };


 export {getLocation, setCity};