import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import '../Css/MyProfile.css';
import { deleteAddress, getUserByUserId, getUserByUserName, updateUser } from '../Services/User';
import { capitalizeFirstLetter } from '../Services/StringConversion';
import { toast } from 'react-toastify';
import { clearBrowser, isTokenValid } from '../Services/Login';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoadingOverlay from "./LoadingOverlay";
import Cookies from 'js-cookie'
import BottomMenu from "./BottomMenu";
import { CartContext } from './CartContext';
import { logOut } from "../Services/Login";
import Loader from './Loader';
import AddressPage from './AddressView';

const MyProfilePage = () => {
    const [processing, setProcessing] = useState(false);
    const { cartCount, setCartCount } = useContext(CartContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('profile');
    const [editProfile, setEditProfile] = useState(false);
    const { isLoading, setIsLoading } = useContext(CartContext);
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const [profileData, setProfileData] = useState({
        name: "",
        emailId: "",
        mobileNo: "",
        address: []
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        setProcessing(true);
        setIsLoading(true);
        if (Cookies.get('login') !== 'true') {
            setIsLoading(false);
            navigate('/login');
        } else {
            if (location.state?.showModal) {
                setShowModal(false);
            }
            isTokenValid(Cookies.get('token'))
                .then((response) => {
                    if (response !== true) {
                        setProcessing(false)
                        setIsLoading(false);
                        clearBrowser();
                        navigate('/login');
                    }
                    else {
                        const fetchData = async () => {
                            getUserByUserId(Cookies.get('userId')).then((response) => {
                                setProfileData({
                                    'name': response.user.name,
                                    'emailId': response.user.emailId,
                                    'mobileNo': response.user.mobileNo,
                                    'address': response.address
                                });
                                setProcessing(false)
                                setIsLoading(false);
                            }).catch((error) => {
                                setProcessing(false);
                                setIsLoading(false);
                                console.log(error);
                            }
                            ).finally();
                        };
                        fetchData();
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("You are not authorized to use this app, please login");
                        navigate('/login');
                    }
                });
        }
    }, [pathname]);

    const handleEditProfile = () => setEditProfile(!editProfile);

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSaveProfile = (e) => {
        e.preventDefault();
        setEditProfile(false);

        updateUser(Cookies.get('userId'), profileData).then((response) => {

            isTokenValid(Cookies.get('token'))
                .then((response) => {
                    setProfileData(response);
                    toast.success("Profile updated successfully!", { position: 'bottom-center' });
                })
                .catch((error) => {
                    console.log(error)
                    setCartCount(0);
                    toast.error(error.response.data.message, { position: 'bottom-center' });
                    navigate('/login');
                });
            // if (localStorage.getItem('userEmail') !== profileData.emailId) {
            //     localStorage.clear()
            //     toast.success("You changed email id, please login again with new email id");
            //     navigate('/login')
            // }

        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message, { position: 'bottom-center' });
        }
        );

    };

    const handleDelete = (userId, addressId) => {
        deleteAddress(userId, addressId).then((response) => {
            if (response.success) {
                getUserByUserId(Cookies.get('userId')).then((response) => {
                    setProfileData({
                        'name': response.user.name,
                        'emailId': response.user.emailId,
                        'mobileNo': response.user.mobileNo,
                        'address': response.address
                    });
                    toast.success("One address deleted");
                }).catch((error) => console.log(error)).finally();
            }
        }).catch((error) => {
            console.log(error);
            toast.error("Something went wrong, please try again", { position: 'bottom-center' });
        })
    }

    const LogOut = () => {
        logOut().then(() => {
            navigate('/');
            clearBrowser();
        });
    }

    const handleCloseModal = () => {
        setShowModal(false);
        // Reset selected quantity when modal is closed
    };

    const handleModal = () => {
        setShowModal(true);
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            {processing && <LoadingOverlay />}
            <div className='profile-wrapper '>
                <Container className="my-profile-page mt-5 ">
                    <Row>
                        <Col md={3}>
                            <div className="sidebar">
                                <ul>
                                    <li className={activeTab === 'profile' ? 'active' : ''} onClick={() => setActiveTab('profile')}>My Profile</li>
                                    <li className={activeTab === 'address' ? 'active' : ''} onClick={() => setActiveTab('address')}>Address</li>
                                    <li className={activeTab === 'logout' ? 'active' : ''} onClick={() => { setActiveTab('logout'); LogOut() }}>Logout</li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={9}>
                            <div className="content ">
                                {activeTab === 'profile' && (
                                    <div className='profile-view '>

                                        <h2>My Profile</h2>
                                        <Form onSubmit={handleSaveProfile} >
                                            <Form.Group controlId="formFirstName">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    value={profileData.name}
                                                    onChange={handleProfileChange}
                                                    readOnly={!editProfile}
                                                    className={editProfile ? 'edit-mode input-field' : 'read-mode input-field'}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formEmailId">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="emailId"
                                                    value={profileData.emailId}
                                                    onChange={handleProfileChange}
                                                    readOnly={!editProfile}
                                                    className={editProfile ? 'edit-mode input-field' : 'read-mode input-field'}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formMobileNo">
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="mobileNo"
                                                    value={profileData.mobileNo}
                                                    onChange={handleProfileChange}
                                                    readOnly={!editProfile}
                                                    className={editProfile ? 'edit-mode input-field' : 'read-mode input-field'}
                                                />
                                            </Form.Group>
                                            {editProfile ? (
                                                <>
                                                    <Button className='edit-button' variant="primary" type="submit" >Save</Button>
                                                    <Button variant="secondary" style={{ marginLeft: '20px' }} onClick={handleEditProfile} className="ml-2 edit-button">Cancel</Button>
                                                </>
                                            ) : (
                                                <Button variant="primary edit-button" onClick={handleEditProfile}>Edit</Button>
                                            )}
                                        </Form>

                                    </div>
                                )}
                                {activeTab === 'address' && (
                                    <>
                                        <Link className="add-address-button" onClick={handleModal}>+ Add new address</Link>
                                        {profileData.address.length > 0 && (
                                            profileData.address.map((address, index) => (
                                                <div key={index} className='address-container'>
                                                    <div className='address-wrapper' style={{ display: 'flex' }}>
                                                        <p>
                                                            {capitalizeFirstLetter(address.address)},
                                                            {capitalizeFirstLetter(address.landmark)},
                                                            {address.pincode},
                                                            {capitalizeFirstLetter(address.cityId.name)},
                                                            {capitalizeFirstLetter(address.cityId.state)}
                                                        </p>
                                                        <button
                                                            className='address-button'
                                                            onClick={() => handleDelete(Cookies.get('userId'), address._id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton style={{ backgroundColor: 'white' }}>
                    <Modal.Title>Add new address</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: 'whitesmoke' }}>
                    <AddressPage />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>

    );
};

export default MyProfilePage;
