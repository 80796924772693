import { useContext } from "react";
import { CartContext } from "./CartContext";
import '../Css/CustomToast.css';
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { BsFillBookFill } from "react-icons/bs"
import { RiAccountCircleFill } from "react-icons/ri";
import { FaShoppingCart } from "react-icons/fa";


const Menu = () => {

    const { cartCount, setCartCount } = useContext(CartContext);

    return (

        <>
            <div >
                <div className="botton-menu-container justify-content-center" >
                    <Link to = "/" className="menu-item ">
                        {/* <i className="bi bi-house-fill icon-large"></i> */}
                        <FaHome className="icon-large"/>
                        <p>Home</p>
                    </Link>
                    <Link  to = "/order" className="menu-item botton-menu-container-item-margin">
                        {/* <i className="bi bi-book-fill icon-large"></i> */}
                        <BsFillBookFill className="icon-large"/>
                        <p>Bookings</p>
                    </Link>
                    <Link to="/profile" className="menu-item botton-menu-container-item-margin">
                        {/* <i className="bi bi-person-fill icon-large"></i> */}
                        <RiAccountCircleFill className="icon-large"/>
                        <p>Account</p>
                    </Link>
                    <Link  to="/cart" className="menu-item botton-menu-container-item-margin">
                        <span className="menu-cart-item-count">
                            {cartCount}
                        </span>
                        {/* <i className="bi bi-cart-fill icon-large"></i> */}
                        <FaShoppingCart className="icon-large"/>
                        <p>Cart</p>
                    </Link>

                </div>
            </div>
        </>

    );

}

export default Menu;