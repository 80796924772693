import React from "react";
import { myAxios } from "./Helper";
import Cookies from 'js-cookie';

async function login(formData) {
    return await myAxios.post('/api/auth/login', formData,{
        withCredentials: true,
    }).then((response) => response);
}

async function isTokenValid(token) {
    return await myAxios.get('/api/auth/verify-token', {
        headers: {
            Authorization: `${token}`
        }
    },
    {
        withCredentials: true,
    })
    .then((response) => response.data.message);
}

async function clearBrowser() {
    localStorage.clear();
    window.location.reload();
}

async function logOut() {
    Cookies.remove('token');
    Cookies.remove('userId');
    Cookies.remove('user_name');
    Cookies.remove('login');
}


export { login, isTokenValid, clearBrowser, logOut };