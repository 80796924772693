import { myAxios } from "./Helper";

async function getOrderDetails(userId) {

    return await myAxios.get('/api/order/user/' + userId,{
      withCredentials: true,
    }).then((response) => response);
 };

 async function cancelOrder(orderId) {

  return await myAxios.put('/api/order/'+orderId+'/cancel',{
    withCredentials: true,
  }).then((response) => response);
};

async function addFeedback(feedback, orderId) {
  return await myAxios.post('/api/order/'+orderId+'/feedback',{feedback},{
    withCredentials: true,
  }).then((response) => response);
}

///////////////////////////////////////////////
async function deleteOrder(orderId) {

    return await myAxios.delete('/order-service/order/delete/' + orderId,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      ).then((response) => response);
 };

 export {getOrderDetails, deleteOrder, cancelOrder, addFeedback};