import React from 'react';
import '../Css/OrderTracker.css'; // Import your CSS file
import { capitalizeFirstLetter } from '../Services/StringConversion';

const OrderTracker = ({ status }) => {
    const statuses = ['received', 'confirmed', 'assigned', 'completed'];

    const statusesCancel = ['received', 'canceled'];
    return (
        <div className="order-tracker">
            <div className="tracker-line"></div>
            <div className="tracker-points">
                {status && status === 4 ? (
                    statusesCancel.map((point, index) => (
                        <div
                            key={index}
                            className={`tracker-point ${status === 4 ? 'cancel' : (status >= index ? 'active' : '')}`}
                        >
                            <div className="point-indicator"></div>
                            <span style={{ fontSize: '11px' }}>{capitalizeFirstLetter(point)}</span>
                        </div>
                    ))
                ) : (
                    statuses.map((point, index) => (
                        <div
                            key={index}
                            className={`tracker-point ${status === 4 ? 'cancel' : (status >= index ? 'active' : '')}`}
                        >
                            <div className="point-indicator"></div>
                            <span style={{ fontSize: '11px' }}>{capitalizeFirstLetter(point)}</span>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default OrderTracker;
