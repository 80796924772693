import React, { useState } from 'react';
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import '../Css/StarRating.css';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { addFeedback } from '../Services/Order';

const StarRating = ({orderId}) => {

    const [showModal, setShowModal] = useState(false);
    const [serviceRating, setServiceRating] = useState(0);
    const [partnerRating, setPartnerRating] = useState(0);
    const [serviceReview, setServiceReview] = useState('');
    const [partnerReview, setPartnerReview] = useState('');
    const [submit, setSubmit] = useState(false);

    const [errors, setErrors] = useState({ service: '', partner: '' });

    const validateService = () => {
        let serviceError = '';
        if (serviceRating === 0) {
            serviceError = "Please rate the service.";
        }
        if (serviceReview.trim() === '') {
            serviceError = "Please leave a review for the service.";
        }
        setErrors((prev) => ({ ...prev, service: serviceError }));
    };

    const validatePartner = () => {
        let partnerError = '';
        if (partnerRating === 0) {
            partnerError = "Please rate the professional.";
        }
        if (partnerReview.trim() === '') {
            partnerError = "Please leave a review for the professional.";
        }
        setErrors((prev) => ({ ...prev, partner: partnerError }));
    };


    const handleServiceRating = (rating) => {
        setServiceRating(rating);
    };

    const handlePartnerRating = (rating) => {
        setPartnerRating(rating);
    };

    const handleServiceReviewChange = (e) => {
        setServiceReview(e.target.value);
        validateService();
    };

    const handlePartnerReviewChange = (e) => {
        setPartnerReview(e.target.value);
        validatePartner();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        const feedback = {
            serviceRating,
            partnerRating,
            serviceReview,
            partnerReview,
        };

        addFeedback(feedback, orderId).then((response) => {
        console.log(response.data);
        setSubmit(true);
        });  

        console.log('Feedback submitted:', feedback);
        // You can send this data to your API or backend as needed
        // Reset the form after submission if desired
        setServiceRating(0);
        setPartnerRating(0);
        setServiceReview('');
        setPartnerReview('');
    };
    const handleCloseModal = () => {
        setShowModal(false);
        // Reset selected quantity when modal is closed
    };

    const handleModal = () => {
        setShowModal(true);
    }


    return (
        <>
        <div>
            <button className='review-button' style={{ color: 'blue', fontSize: '14px', marginTop: '-3px' }} onClick={handleModal}>Write a Review</button>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton style={{ backgroundColor: 'white' }}>
                <Modal.Title>Review And Rate</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: 'white' }}>
                {submit === true ? (<p style={{textAlign:'center'}}>Thanks for your review</p>) : (
                    <form onSubmit={handleSubmit}>
                    <div>
                        <p className='Rate-heading'>Rate our Service</p>
                        <div className="star-rating d-flex align-items-center">
                            {[...Array(5)].map((_, index) => (
                                <span key={index} onClick={() => handleServiceRating(index + 1)}>
                                    <FontAwesomeIcon className='star-icon' icon={index < serviceRating ? solidStar : regularStar} />
                                </span>
                            ))}
                        </div>
                        <label htmlFor="service-review">Leave your review:</label>
                        <textarea
                            id="service-review"
                            value={serviceReview}
                            onChange={handleServiceReviewChange}
                            rows="4"
                            placeholder="Write your review about the service here..."
                            style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                        />
                        {errors.service && <p style={{ color: 'red' }}>{errors.service}</p>} {/* Display service errors */}
                    </div>
                    <div>
                        <p className='Rate-heading'>Rate our Professional</p>
                        <div className="star-rating d-flex align-items-center">
                            {[...Array(5)].map((_, index) => (
                                <span key={index} onClick={() => handlePartnerRating(index + 1)}>
                                    <FontAwesomeIcon className='star-icon' icon={index < partnerRating ? solidStar : regularStar} />
                                </span>
                            ))}
                        </div>
                        <label htmlFor="partner-review">Leave your review:</label>
                        <textarea
                            id="partner-review"
                            value={partnerReview}
                            onChange={handlePartnerReviewChange}
                            rows="4"
                            placeholder="Write your review about the professional here..."
                            style={{ width: '100%', marginTop: '10px' }}
                        />
                        {errors.partner && <p style={{ color: 'red' }}>{errors.partner}</p>} {/* Display partner errors */}
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <button
                            type="submit"
                            className="submit-review-button"
                            disabled={serviceRating === 0 || partnerRating === 0} // Disable button if there are errors
                        >
                            Submit
                        </button>
                    </div>
                </form>
                )}
                
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </>
    );
};

export default StarRating;
