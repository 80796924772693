import React from "react";
import { myAxios } from "./Helper";



async function addItemToCart(userId, itemId, productId, cityId) {
    try {
        const response = await myAxios.put('/api/cart/userId/'+userId+'/addItemToCart',
            {
                "itemId": itemId,
                "productId": productId,
                "city_id": cityId
            },
            {
                withCredentials: true
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error adding item to cart:", error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

async function getCart(userId) {
    return await myAxios.get('/api/cart/userId/' + userId, {
        withCredentials: true
    })
        .then((response) => response.data);
}

async function updateCart(itemId, userId, quantity) {
    try {
        const response = await myAxios.put(
            `/api/cart/userId/${userId}/updateCart`,
            {
                itemId: itemId,
                quantity: quantity
            },
            {
                withCredentials: true
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error adding item to cart:", error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

async function deleteCartItem(userId, itemId) {
    return await myAxios.delete(`/api/cart/userId/${userId}/itemId/${itemId}/delete`,
        {
            withCredentials: true
        })
        .then((response) => response.data);
}

async function deleteAllCartItem(userId) {
    return await myAxios.delete(`/api/cart/userId/${userId}/delete-all`,
        {
            withCredentials: true
        })
        .then((response) => response.data);
}

async function generateSlot(serviceName) {
    return await myAxios.get('/api/slot/service-name/'+serviceName+'/generate-slot', {
        withCredentials: true
    })
        .then((response) => response.data);
}

////////////////////////

// async function deleteAllCartItem(cartId) {
//     return await myAxios.delete('/cart-service/cart/' + cartId + '/delete/allItem',
//         {
//             headers: {
//                 'Authorization': `Bearer ${localStorage.getItem('token')}`
//             }
//         })
//         .then((response) => response.data);
// }


async function updateCartItemLength(response) {
    if (response) {
        localStorage.setItem('cartItem', response.cartitems.length);
    }
};

export {generateSlot, addItemToCart, getCart, updateCart, deleteCartItem, updateCartItemLength, deleteAllCartItem };