import React, { useContext, useEffect, useState } from "react";
import '../Css/Home.css'
import '../Css/Login.css'
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { login, isTokenValid } from "../Services/Login";
import { getUserByUserId, getUserByUserName } from "../Services/User";
import { getCart } from "../Services/Cart";
import { toast } from 'react-toastify';
import LoadingOverlay from "./LoadingOverlay";
import Cookies from "js-cookie";
import { CartContext } from "./CartContext";
import Menu from "./BottomMenu";


const Login = () => {
    const { cartCount, setCartCount } = useContext(CartContext);
    const { userName, setUserName } = useContext(CartContext);
    const [processing, setProcessing] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        mobileNo: '',
        password: '',
        rememberMe: false,
    });

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        const val = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: val });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);

        login(formData).then((response) => {
            if (response.status === 200) {
                // Handle successful login (e.g., redirect or store user info)
                Cookies.set('userId', response.data.userId, { expires: 7 });
                Cookies.set('login', true, { expires: 7 });
                console.log(response.data);
                isTokenValid(Cookies.get('token')).then((response) => {
                    if (response !== true) {
                        navigate('/login');
                    } else {
                        getCart(Cookies.get('userId')).then((response) => {
                            setCartCount(response.data.cartItems.length);

                        }).catch((error) => console.log(error));

                        getUserByUserId(Cookies.get('userId')).then((response) => {
                            let name = response.user.name;

                            // Split the full name into an array of words
                            let nameParts = name.split(' ');

                            // Get the first part (first name)
                            let firstName = nameParts[0];

                            setUserName(firstName);
                            Cookies.set('user_name', firstName, { expires: 7 })
                        }).catch((error) => console.log(error));
                    }
                }).catch((error) => console.log(error));
                navigate('/');
            } else {
                // Handle unexpected response status
                toast.error("Login failed. Please try again.", { position: 'bottom-center' });
            }
        }).catch((error) => {
            setProcessing(false);
            toast.error("Invalid mobile number or password", { position: 'bottom-center' });
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            {processing && <LoadingOverlay />}
            <div className='login-wrap justify-content-center align-items-center d-flex'>
                <div className="login-c ">
                    <Form className="shadow p-4 bg-white rounded position-relative" onSubmit={handleSubmit}>
                        {/* Header */}
                        <div className="h4 mb-4 text-center">Sign In</div>

                        <Form.Group className="mb-3" controlId="mobileNo">
                            <Form.Label>Mobile No</Form.Label>
                            <Form.Control
                                type="text"
                                name="mobileNo"
                                placeholder="Enter your mobile number"
                                value={formData.mobileNo}
                                onChange={handleChange}
                                required
                                className="p-3"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                placeholder="Enter your password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                className="p-3"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 d-flex justify-content-between align-items-center" controlId="rememberMe">
                            {/* <Form.Check
                                            type="checkbox"
                                            name="rememberMe"
                                            label="Remember me"
                                            checked={formData.rememberMe}
                                            onChange={handleChange}
                                        /> */}
                            <Button
                                className="text-muted px-0"
                                variant="link"
                            >
                                Forgot password?
                            </Button>
                        </Form.Group>

                        <Button className="w-100 btn-lg btn-primary mb-3" type="submit" >
                            Login
                        </Button>

                        <div className="d-flex justify-content-between">
                            <Link to="/register" className="link">
                                New User? Create an account
                            </Link>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default Login;