import { myAxios } from "./Helper";

async function register(formData) {
    return await myAxios.post('/api/auth/user-register', formData)
        .then(response => response);
        
}

export {register};

